
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Avatar",
  props: {
    avatar: {type: String}
  },
  setup(props) {
    const src = ref(props.avatar !==  null && props.avatar != undefined ? props.avatar : '/media/avatars/blank.png')
    return {
      src,
    }
  }
})
