
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "PageStatus",
  props: {
    status: {required: true}
  },
  setup: function (props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'PUBLISHED':
          data.value = {name: 'Published', clazz: 'success'}
          break
        case 'DRAFT':
          data.value = {name: 'Draft', clazz: 'primary'}
          break
        case 'DELETED':
          data.value = {name: 'DELETED', clazz: 'error'}
          break
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
