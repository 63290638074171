import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { IFilter } from "@/core/entity/IFilter";
import { ISearchFilter } from "@/core/entity/ISearchFilter";
import { ISearchField } from "@/core/entity/ISearchField";
import { useRouter } from "vue-router";
import { ICompany } from "@/core/entity/ICompany";
import PolicyService from "@/core/services/PolicyService";
import { ITableResponse } from "@/core/entity/ITableResponse";
import { IPolicy } from "@/core/entity/IPolicy";
import UserService from "@/core/services/UserService";
import { IUser } from "@/core/entity/IUser";

const PAGINATION_SIZE = 50;
export const LoadEntitiesEvent = (action: Actions, expand: any[] = []) => {
  const store = useStore()
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: '', ep: expand.join(",")},
  })

  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    store.dispatch(action, filter.value)
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    store.dispatch(action, filter.value);
  };

  const paginationLoad = () => {
    store.dispatch(action, filter.value)
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
  }
}

export const LoadEntitiesFromEntityEvent = (action: Actions) => {
  const router = useRouter();
  const id = ref(router.currentRoute.value.params.id)
  const store = useStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: ''},
  })

  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };

  const paginationLoad = () => {
    store.dispatch(action, {id: id.value, filter: filter.value})
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
  }
}

export const SearchEntityEvent = (action: Actions, query: ISearchField[], fields: string[], expand: string[], entity: string) => {
  const store = useStore();
  const filter = ref<ISearchFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    query: query,
    entity: entity,
    fields: fields,
    expand: expand
  })

  const paginationLoad = () => {
    store.dispatch(action, filter.value)
  }
  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    store.dispatch(action, filter.value)
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    store.dispatch(action, filter.value);
  };
  const searchEvent = () => {
    paginationLoad();
  }
  const discardEvent = () => {
    filter.value.query = filter.value.query.map(item => Object.assign(item, {
      name: item.name,
      type: item.type,
      column: item.column,
      operator: item.operator,
      value: item.type === 'HIDDEN' ? item.value : ''
    }))
    paginationLoad();
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    searchEvent,
    discardEvent
  }
}

export const LoadEntity = (action: Actions) => {
  const router = useRouter();
  const id = ref(router.currentRoute.value.params.id)
  const store = useStore();
  const reloadData = (id) => {
    store.dispatch(action, id)
  }
  watch(() => router.currentRoute.value.params.id, (cb) => {
    console.log(cb)
    if (cb !== undefined) reloadData(cb);
  })
  reloadData(id.value);
  return {
    reloadData,
    id
  }
}

export const HandlePanel = (action: Actions) => {
  const isShow = ref(false);
  const panelLoading = ref(true);
  const store = useStore();
  const onClosePanel = () => {
    isShow.value = false;
  }
  const onOpenPanel = () => {
    isShow.value = true
  }
  const loadPanelData = (id) => {
    panelLoading.value = true
    isShow.value = true
    store.dispatch(action, id).then(() => {
      panelLoading.value = false
    });
  }
  return {
    isShow,
    onClosePanel,
    onOpenPanel,
    loadPanelData,
  }
};


export const HandleState = () => {
  const store = useStore();
  const onCountrySelectedEvent = (country: string) => {
    store.dispatch(Actions.LOAD_STATE, country)
  }
  return {
    onCountrySelectedEvent
  }
}

export const CompanySelect = () => {
  const store = useStore();

  const loadCompany = () => {
    store.dispatch(Actions.LOAD_CLIENTS)

  }
  loadCompany();
  const clients = computed<ICompany[]>(() => store.state.CompanyModule.clientSelect)
  return {
    loadCompany,
    clients,
  }
}

export const PolicySelect = () => {
  const filter = ref({clientId: '', number: '', f: 'policyId,number'})
  const policies = ref<any>([])
  const loadPolicy = () => {
    PolicyService.listFilter<ITableResponse<any>>(filter.value).then(res => {
      policies.value = res.data
    })
  }
  loadPolicy()
  return {
    policies,
  }
}

export const InsuredSelect = (policyId: string) => {
  const insureds = ref<any>([])
  const loadInsured = (policyIy: string) => {
    PolicyService.get<IPolicy>(policyIy).then(res => {
      insureds.value = res.insureds;
    })
  }
  if (policyId) {
    loadInsured(policyId)
  }
  return {
    loadInsured,
    insureds
  }
}

export const UserSelect = () => {
  const users = ref<IUser[]>([])
  const loadUser = () => {
    return UserService.listFilter<ITableResponse<IUser>>({}).then(res => {
      users.value = res.data
    })
  }
  loadUser();
  return {
    users,
    loadUser
  }
}


export const LoadFilterObjects = (action: Actions, filterObject: any, expands: any[] = [], sort = '') => {
  const store = useStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {ep: expands.join(","), s: sort},
  })
  filter.value.key = Object.assign(filter.value.key, filterObject);
  const updateFilterObject = (filterObject) => {
    filter.value.key = Object.assign(filter.value.key, filterObject);
    paginationLoad()
  }
  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };

  const paginationLoad = () => {
    store.dispatch(action, filter.value);
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    updateFilterObject,
  }
}