import { BaseService } from "@/core/services/BaseService";
import { ENDPOINT } from "@/core/config/Constant";
import { ICase } from "@/core/entity/ICase";

class PolicyService extends BaseService<any> {
  endpoint = ENDPOINT.POLICY;

  async getCases(id: string) {
    return await this.http().get<ICase>(this.endpoint + "/" + id + "/cases").then(res => {
      return res.data
    })
  }
}

export default new PolicyService();